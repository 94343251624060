import React, { createContext, useContext, useRef, useState } from "react";
// import { create } from "zustand";
import { createStore, useStore } from 'zustand'
import FlashNotice from "./FlashNotice";

const ProductStoreContext = createContext()



const createProductStore = (initialProps) => {
  const defaultProps = {
    product: {},
    productVariants: [],
    selectedProductVariant: null,
    quantity: 1,
    addToCartPath: ""
  }
  // only show the product variants that are available to purchase
  const filteredProductVariants = initialProps.productVariants.filter((pv) => pv.quantity > 0);
  return createStore()(set => (
    {
      ...defaultProps,
      ...initialProps,
      productVariants: filteredProductVariants,
      setQuantity: (quantity) => set({ quantity }),
      setProductVariant: (productVariant) => set({ selectedProductVariant: productVariant })
    }))
}

const ProductPageProvider = (props) => {
  const productStore = useRef(createProductStore(props)).current
  return (
    <ProductStoreContext.Provider value={productStore}>
      <ProductPage />
    </ProductStoreContext.Provider>
  )
}

const ProductPage = (props) => {
  const store = useContext(ProductStoreContext)
  const { product, addToCartPath, productVariants, selectedProductVariant, privateProduct, supportEmail, orgName } = useStore(store)

  const [showFlash, setShowFlash] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');

  const setProductVariant = useStore(store, (s) => s.setProductVariant)
  const quantity = useStore(store, (s) => s.quantity)
  const setQuantity = useStore(store, (s) => s.setQuantity)
  const setProductQuantity = (quantity) => {
    if (quantity > 0 && quantity <= selectedProductVariant.quantity) {
      setQuantity(quantity)
    }
  }

  const fullAddToCartPath = addToCartPath + "?product_variant_id=" + selectedProductVariant.id + "&product_variant_qty=" + quantity
  const checkoutPath = addToCartPath + "?product_variant_id=" + selectedProductVariant.id + "&product_variant_qty=" + quantity + "&checkout=true"

  const handleClick = (event) => {
    event.preventDefault();
    setShowFlash(false);
    setTimeout(() => {
      if (privateProduct) {
        setFlashMessage("In order to purchase this product, you must be a member of " + orgName + ". Please contact " + supportEmail + " for questions.");
        setShowFlash(true);
      }
    }, 0);
  }

  return (
    <>
      <div className="w-full h-64 md:w-1/2 lg:h-96">
        {product.imageUrl ? (
          <img src={product.imageUrl}
            className="h-full w-full rounded-md object-cover max-w-lg mx-auto"
            alt={product.name} />

        ) : (
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARgAAAC0CAMAAAB4+cOfAAAAPFBMVEXq6uqurq7X19fi4uKqqqrp6ene3t6vr6+0tLTHx8fl5eW3t7fd3d3t7e3U1NTZ2dm9vb3MzMzCwsKlpaXgX8HoAAAEWElEQVR4nO3a626rOhAFYOPBd3s8dt//Xc+YJLupdqV0/zilFetT2xAKCK8MJgaMAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA+F858nd09q78LFn2u+DO3pcfpLUs211w7VNn7+MJGo00459g0qeGP3s3vx/HsL0W+ez9/G41vk5lKVerGXs7gl4n08/e029md60G5vkxmlBE5EMt7fZiHbAGs9fn89KKZXRPRJXnxYPRJrenDCa5Zo5TtOvxPZiz9/SbrWC0zVT+5DLWdzxHlY7XcuGK2bY0nnLRmV67nLDFoQOEXK4czBPJ60xVEndOUaqezwOCWbi1HiblaqvzojG5dNk+5sN5eo0oi2OJMYqlogeWDagYPZKaBkHpnhJzbMYXBKPdcGtD+DEvcMiGBMHoOUmDeR9rbymQyYI+5lYxT0OBuDlUzKOP6c9jpNJMLagY7VX0a8zzCImN4Q0Vc3Qyz8OD5O5dDIKJ3jSS27fdmPKjYBDMJjpAcjxFJHVn2qPHuXofs5LxzTRHREZrxD56YlSMnomGud80oXTp6zF/i8l68ixP1ztRMfcclg8zrlYx/St3lZar3SUgeZ3JIpe73V/ntr8U5tXut5l13fsL8BAEAADA6dzXz8dr0ffF71P/sP7pcq3OuK89oJrZfrVljjnTeGyVxjFUyOMXjRhsidxIklnPZb7Pfp98ehgzD3YfF/t7mfsbxyPbzd4fFKlxrBfaUvt0/R+ocdyip6LB9MF07HPuvo/aXCfSv6xpOEuu9Zp7Ncbz6I06GerZ+WM0TTxGdWte7pT1jTWudteDdX2srWowXauF1g0pGr+ibhoH2WfVihmhxHCMdXyIEgORzhAjsYSSZeYc0iqsvhazq4kpchZZJTR0+WhXWfDW+6Yrs0lCGkyWIkFyLUFn9rVWj6WEcXazX9Ng+txmSXlPLUdZJVM3bj4m2lMl1jaOjUfwHOoKJkrOJTqZPkbRLNZGstPlkisxz5JdJi7FzCMY7W6rxOo1M4qphqFJZY3z5w/CNRi7nodKK41W4i2Y1YxJu84ZWylFtGUsxaxgdv3ERdooWjAl3UpMg5CQDIdRhvHrjdyDyTNKKX4Fo1WnwZAE/X/6FcFws2FLWX/8o2KG00YewfA2vLfezbLSWhVTuq/riIjTac1ks54IiVRjclmPstpm7L7cg+l9Hy7FFYyzIXnd8CyWqs1nt/slbTk3l/ZkUpASb33Mpp95yf5ttNVokWh1sT0bKlPPYkWXa1TerJlv6XgCL20yt+Sa9lZZa0xm0EOpkN3tOt70UKqxaF/l9eBsx/rz51eMyT6vP6TnEebb/tYtrUnnj3KoPLwzmfxxTUZ/me16WTP97ZPPlml9E1qbMa6z1+WInG7Aea5risjet+h0in/phZvVx5y9Dz9Sthe8QgkAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAp/gPjOMyqC28HqMAAAAASUVORK5CYII="
            className="h-full w-full rounded-md object-cover max-w-lg mx-auto"
            alt={product.name} />
        )
        }
      </div>
      <div className="w-full max-w-lg mx-auto mt-5 md:ml-8 md:mt-0 md:w-1/2">
        <h3 className="text-gray-700 uppercase text-lg"> {product.name}</h3>
        <span className="text-gray-500 mt-3">
          ${selectedProductVariant.price}
        </span>
        <hr className="my-3" />
        <div className="mt-2">
          <p className="text-gray-700 " dangerouslySetInnerHTML={{ __html: product.description }} />
        </div>
        <div className="mt-2">
          <label className="text-gray-700 text-sm" htmlFor="count">Count:</label>
          <div className="flex items-center mt-1">
            <button onClick={() => setProductQuantity(quantity - 1)} className="text-gray-500 focus:outline-none focus:text-gray-600">
              <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </button>
            <span className="text-gray-700 text-lg mx-2">
              {quantity}
            </span>
            <button onClick={() => setProductQuantity(quantity + 1)} className="text-gray-500 focus:outline-none focus:text-gray-600">
              <svg className="h-5 w-5" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </button>
          </div>
        </div>
        <div className="mt-3">
          <label className="text-gray-700 text-sm block mb-2" htmlFor="count">Type:</label>
          <nav className="relative mt-1 z-0 flex flex-wrap" aria-label="">
            {productVariants.map(pv => {
              return <button key={pv.id}
                className={`relative inline-flex mr-2 items-center px-2 py-2 rounded-md border text-sm font-medium hover:text-gray-700 hover:bg-gray-50 ${selectedProductVariant.id === pv.id ? 'bg-indigo-600 text-white border-indigo-600' : 'text-gray-500 border-gray-300'}`}
                onClick={() => setProductVariant(pv)} >
                {pv.name}
              </button>
            })}
          </nav>
        </div>
        <div className="flex items-center mt-6">
          {showFlash && <FlashNotice type="error" msg={flashMessage} />}
          <a rel="nofollow"
            data-method="post"
            href={privateProduct ? 'javascript:(0)' : checkoutPath}
            className="px-8 py-2 bg-indigo-600 text-white text-sm font-medium rounded hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500"
            onClick={handleClick}
            >
            Order Now</a>
          <a rel="nofollow" 
            data-method="post" 
            href={privateProduct ? 'javascript:(0)' : fullAddToCartPath} 
            className="mx-2 text-gray-600 border rounded-md p-2 hover:bg-gray-200 focus:outline-none"
            onClick={handleClick}
            >
            Add To Cart
            <svg className="h-5 w-5 inline" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
          </a>
        </div>
      </div>

    </>
  );
};

export default ProductPageProvider;
