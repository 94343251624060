import React, { useState, useLayoutEffect } from 'react'
// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const StripeSDKLoader = ({ stripe_publishable_key, children }) => {
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [stripe, setStripe] = useState(null);

  useLayoutEffect(() => {
    const promise = loadStripe(stripe_publishable_key)
    setStripe(promise)
    setSdkLoaded(true)
  }, [])

  return (
    sdkLoaded ? (
      <Elements stripe={stripe}>
        {children}
      </Elements>
    ) : (
      <p>Loading ...</p>
    )
  )
}
export { StripeSDKLoader }