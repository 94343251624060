import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'month', label: 'Monthly', priceSuffix: '/month' },
  { value: 'year', label: 'Annually', priceSuffix: '/year' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DashboardPlans(props) {
  const { tiers, current_plan } = props
  const [frequency, setFrequency] = useState(frequencies[(current_plan && current_plan.interval == "month" ? 0 : 1)])

  return (
    <div className="py-4">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 bg-white text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier[`${frequency.value}_price`].is_subbed ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10 bg-white'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier[`${frequency.value}_price`].is_subbed ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier[`${frequency.value}_price`].is_subbed ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    subscribed
                  </p>
                ) : null}
              </div>
              {/* <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p> */}
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">{tier[`${frequency.value}_price`].human_amount}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
              </p>
              <a
                href={
                  (tier[`${frequency.value}_price`].is_subbed ?
                    null :
                    `/dashboard/settings/plan/${frequency.value == "month" ? tier.month_price.id : tier.year_price.id}/edit`
                  )
                }
                aria-describedby={tier.id}
                className={classNames(
                  tier[`${frequency.value}_price`].is_subbed
                    ? 'bg-indigo-600 text-white shadow-sm'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:bg-gray-100 hover:ring-indigo-300',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                {tier[`${frequency.value}_price`].is_subbed ? "Current Plan" : "Buy plan"}
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.feature_list.map((feature, i) => (
                  <li key={i} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
