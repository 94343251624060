/**
 * Calculate stripe fee from amount
 * so you can charge stripe fee to customers
 * lafif <hello@lafif.me>
 */
var fees = {
  non_profit: {
    Percent: 2.2,
    Fixed: 0.3,
  },
  USD: {
    Percent: 2.9,
    Fixed: 0.3,
  },
  GBP: {
    Percent: 2.4,
    Fixed: 0.2,
  },
  EUR: {
    Percent: 2.4,
    Fixed: 0.24,
  },
  CAD: {
    Percent: 2.9,
    Fixed: 0.3,
  },
  AUD: {
    Percent: 2.9,
    Fixed: 0.3,
  },
  NOK: {
    Percent: 2.9,
    Fixed: 2,
  },
  DKK: {
    Percent: 2.9,
    Fixed: 1.8,
  },
  SEK: {
    Percent: 2.9,
    Fixed: 1.8,
  },
  JPY: {
    Percent: 3.6,
    Fixed: 0,
  },
  MXN: {
    Percent: 3.6,
    Fixed: 3,
  },
};

export function calcFee(amount, feeModel, autoPay = false, offline = false) {
  if (feeModel.percent_structure_enabled) {
    return calcNewFee(amount, feeModel, autoPay, offline);
  } else {
    return calcOldFee(amount, feeModel, autoPay, offline);
  }
}

function calcNewFee(amount, feeModel, autoPay = false, offline) {
  var _fee = feeModel;

  const subscriptionPercent = parseFloat(
    (parseFloat(_fee.subscription_percent) + parseFloat(_fee.percent)) / 100
  ); // 0.5% fee for subs
  const singleChargePercent = parseFloat(_fee.percent / 100);

  if (feeModel.fee_type === "union_pays" || offline == true) {
    return {
      amount: amount,
      stripeFee: 0,
      unionlyFee: 0,
      totalFee: 0,
      total: !!amount ? amount.toFixed(2) : amount,
    };
  }

  const amount_cents = amount * 100;

  const unionlyFee =
    (amount_cents * (parseFloat(feeModel.ecommerce_percent) / 100.0)) / 100.0;

  var amount_with_unionly_fee = parseFloat(amount) + unionlyFee;

  var totalWithUnionlyFees =
    (amount_with_unionly_fee + parseFloat(_fee.fixed)) /
    (1 - parseFloat(_fee.percent) / 100);

  var subscriptionTotalWithUnionlyFees =
    (amount_with_unionly_fee + parseFloat(_fee.fixed)) /
    (1 - subscriptionPercent);

  var total = autoPay
    ? subscriptionTotalWithUnionlyFees // need to call toFixed or we are short
    : totalWithUnionlyFees;

  var stripeFee = total - amount_with_unionly_fee;
  var totalFee = total - amount;

  return {
    amount: amount,
    stripeFee: stripeFee.toFixed(2),
    unionlyFee: unionlyFee.toFixed(2),
    totalFee: totalFee.toFixed(2),
    total: total.toFixed(2),
  };
}

function calcOldFee(amount, feeModel, autoPay = false, offline) {
  var _fee = feeModel;
  const subscriptionPercent = parseFloat(
    (parseFloat(_fee.subscription_percent) + parseFloat(_fee.percent)) / 100
  ); // 0.5% fee for subs
  const singleChargePercent = parseFloat(_fee.percent / 100);

  if (feeModel.fee_type === "union_pays" || offline) {
    return {
      amount: amount,
      stripeFee: 0,
      unionlyFee: 0,
      totalFee: 0,
      total: !!amount ? amount.toFixed(2) : amount,
    };
  }

  var amount_with_unionly_fee = parseFloat(
    amount + parseFloat(feeModel.unionly_fee) / 100.0
  );

  var totalWithUnionlyFees =
    (amount_with_unionly_fee + parseFloat(_fee.fixed)) /
    (1 - parseFloat(_fee.percent) / 100);

  var subscriptionTotalWithUnionlyFees =
    (amount_with_unionly_fee + parseFloat(_fee.fixed)) /
    (1 - subscriptionPercent);

  var total = autoPay
    ? subscriptionTotalWithUnionlyFees // need to call toFixed or we are short
    : totalWithUnionlyFees;

  var stripeFee = total - amount_with_unionly_fee;
  var totalFee = total - amount;

  return {
    amount: amount,
    stripeFee: stripeFee.toFixed(2),
    unionlyFee: feeModel.unionly_fee.toFixed(2),
    totalFee: totalFee.toFixed(2),
    total: total.toFixed(2),
  };
}
