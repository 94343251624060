
/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const CtaFooter = (props) => {
  const item = props.footer
  return (
    <>
      <div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">

        <div className="flow-root">
          <a
            href={item.link_1_url.cached_url}
            className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
          >
            <img src={item.link_1_icon.filename} className="h-6 w-6 flex-shrink-0 text-gray-400" />
            <span className="ml-3">{item.link_1_label}</span>
          </a>
        </div>
        <div className="flow-root">
          <a
            href={item.link_2_url.cached_url}
            className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
          >
            <img src={item.link_2_icon.filename} className="h-6 w-6 flex-shrink-0 text-gray-400" />
            <span className="ml-3">{item.link_2_label}</span>
          </a>
        </div>

      </div>
    </>
  )
}

const ListFooter = (props) => {
  const item = props.footer
  return (
    <>
      <div className="bg-gray-50 px-5 py-5 sm:px-8 sm:py-8">
        <div>
          <h3 className="text-base font-medium text-gray-500">{item.title}</h3>
          <ul role="list" className="mt-4 space-y-4">
            {item.links.map((post) => (
              <li key={post._uid} className="truncate text-base">
                <a href={post.url.cached_url} className="font-medium text-gray-900 hover:text-gray-700 hover:underline">
                  {post.label}
                </a>
              </li>
            ))}
          </ul>
        </div >
        <div className="mt-5 text-sm">
          <a href={item.view_all_url.cached_url} className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline">
            {item.view_all_text}
            <span aria-hidden="true"> &rarr;</span>
          </a>
        </div>
      </div >
    </>
  )
}

const NavLink = ({ item }) => {
  const { label, link_url, nested_nav_links, footer } = item
  let FooterInstance = footer[0] && (footer[0].component === "cta_footer" ? CtaFooter : ListFooter)
  return (
    <>
      {!item.has_flyout ? (
        <a href={link_url.cached_url} className="text-base font-medium text-indigo-300 hover:text-indigo-700">
          {label}
        </a>
      ) : (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open ? 'text-indigo-700' : 'text-indigo-300',
                  'group pl-1 inline-flex items-center rounded-md text-base font-medium hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-900'
                )}
              >
                <span>{label}</span>
                <ChevronDownIcon
                  className={classNames(
                    open ? 'text-indigo-700' : 'text-indigo-300',
                    'ml-2 h-5 w-5 group-hover:text-indigo-700'
                  )}
                  aria-hidden="true"
                />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative grid gap-6 bg-white py-6 px-5 sm:gap-8 sm:p-8">
                      {nested_nav_links.map((item) => (
                        <a
                          key={item._uid}
                          href={item.link_url.cached_url}
                          className="-m-3 flex items-start group rounded-lg p-3 hover:bg-gray-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-50 group-hover:bg-indigo-500 text-white sm:h-12 sm:w-12" dangerouslySetInnerHTML={{ __html: item.icon }}>
                          </div>

                          {/* <img src={item.icon.filename} className="h-6 w-6 flex-shrink-0 text-indigo-600" /> */}
                          <div className="ml-4">
                            <p className="text-base font-medium text-gray-900">{item.label}</p>
                            <p className="mt-1 text-sm text-gray-500">{item.sub_text}</p>
                          </div>
                        </a>
                      ))}
                    </div>

                    {FooterInstance && <FooterInstance footer={footer[0]} />}
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
    </>
  )
}

const MobileMenu = ({ mobileMenu, loggedIn }) => {
  // const content = blok.content.body[0]
  return (
    <Transition
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95" >

      <Popover.Panel
        focus
        className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
      >
        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="space-y-6 px-5 pt-5 pb-6">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-8 w-auto"
                  src={mobileMenu.icon.filename}
                  alt="Unionly"
                />
              </div>
              <div className="-mr-2">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                {mobileMenu.top_nav_links.map((item) => (
                  <a
                    key={item._uid}
                    href={item.link_url.cached_url}
                    className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                  >
                    <img src={item.icon.filename} className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden='true' />
                    <span className="ml-3 text-base font-medium text-gray-900">{item.label}</span>
                  </a>
                ))}
              </nav>
            </div>
          </div>
          <div className="space-y-6 py-6 px-5 bg-gray-50 rounded-b-md">
            <div className="grid grid-cols-2 gap-y-4 gap-x-8">
              {mobileMenu.bottom_nav_links.map((item) => (
                <a
                  key={item._uid}
                  href={item.url.cached_url}
                  className="text-base font-medium text-gray-900 hover:text-gray-700 hover:underline"
                >
                  {item.label}
                </a>
              ))}
            </div>
            <div>
              {loggedIn ? (
                <a
                  href="/dashboard"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Dashboard
                </a>
              ) : (<>
                <a
                  href="/pricing"
                  className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                >
                  Sign up
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?
                  <a href="/users/sign_in" className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </a>
                </p>
              </>
              )}
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
}

const StoryblokNav = ({ blok, loggedIn }) => {
  const content = blok.content.body[0]
  return (
    <div className="">
      <div className="">
        {/* Header */}
        <Popover className="relative">
          <div className="mx-auto max-w-7xl px-6">
            <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                  <span className="sr-only">Unionly</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={content.logo_image.filename}
                    alt="logo"
                  />
                </a>
              </div>
              <div className="-my-2 -mr-2 md:hidden">
                <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-indigo-900 hover:bg-indigo-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                {/* rendering menu for real */}
                {content.nav_links.map((item) => (<NavLink key={item._uid} item={item} />))}
                {/* end rendering menu for real */}
              </Popover.Group>
              <div className="hidden items-center justify-end space-x-8 md:flex md:flex-1 lg:w-0">
                {loggedIn ? (
                  <>
                    <a href="/dashboard" className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 shadow hover:bg-indigo-500 hover:text-white">
                      Dashboard
                    </a>
                  </>
                ) : (<>
                  <a href="/users/sign_in" className="whitespace-nowrap text-base font-medium text-indigo-300 hover:text-white">
                    Sign in
                  </a>
                  <a
                    href="/pricing"
                    className="inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-white py-2 px-4 text-base font-medium text-indigo-600 shadow-sm hover:bg-indigo-50">
                    Sign up
                  </a>
                </>
                )}
              </div>
            </div>
          </div>

          {/* this is the mobile menu I believe */}
          <MobileMenu mobileMenu={content.mobile_menu[0]} loggedIn={loggedIn} />

        </Popover>

      </div >
    </div >
  )
}

export default StoryblokNav;