import React, { useState } from "react";
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ToggleButton = ({ toggleState, url }) => {
  const [toggle, setToggle] = useState(toggleState);


  const handleToggle = async (e) => {
    setToggle(!toggle)
  }

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          AutoPay On
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
        </Switch.Description>
      </span>
      <input type="hidden" name="fund[auto_invoice]" value={toggle} />

      <a type="button"
          onClick={handleToggle}
          data-method="post"
          href={`${url}`}
          className={classNames(
            toggle ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
          )}
          role="switch"
          aria-checked="false">
        <span className="sr-only">autopay on</span>
        <span aria-hidden="true"
                className={classNames(
                  toggle ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
        ></span>
      </a>
    </Switch.Group>
  )
}
export default ToggleButton