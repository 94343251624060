import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeFormProvider, useForm } from './StateManagement';
import FormInput from '../FormInput';
import { StripeSDKLoader } from '../../utils/StripeSdkLoader';
import { getCoupon } from '../../utils/requests';

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      color: '#303238',
      fontSize: '16px',
      fontFamily: '"Rubik", sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#6B6B6B',
      },
    },
    invalid: {
      color: '#e5424d',
      ':focus': {
        color: '#303238',
      },
    },
  },
};

const FormFields = () => {
  const { state, updateModel } = useForm();
  const { model, loading, errors } = state;

  return (
    <>
      <div className="mt-4">
        <FormInput
          required
          errors={errors['email'] || []}
          model={model}
          setValue={(e) => updateModel('email', e)}
          label="Email"
          modelName="signup_form"
          value={model.email}
          attribute="email"
          type="text"
        />
      </div>

      <div className="mt-4">
        <FormInput
          required
          errors={errors['password'] || []}
          model={model}
          label="Password"
          modelName="signup_form"
          attribute="password"
          type="password"
        />
      </div>

      <div className="mt-4">
        <FormInput
          required
          errors={errors['password_confirmation'] || []}
          model={model}
          label="Password Confirmation"
          modelName="signup_form"
          attribute="password_confirmation"
          type="password"
        />
      </div>

      <div className="mt-4">
        <FormInput
          required
          errors={errors['name'] || []}
          model={model}
          setValue={(e) => updateModel('name', e)}
          value={model.name}
          label="Organization Name"
          modelName="signup_form"
          attribute="name"
          type="text"
        />
      </div>

      <div className="mt-4">
        <FormInput
          required
          errors={errors['full_name'] || []}
          model={model}
          setValue={(e) => updateModel('full_name', e)}
          value={model.full_name}
          label="Name On Card"
          modelName="signup_form"
          attribute="full_name"
          type="text"
        />
      </div>

      <div className="mt-4">
        <label className="block text-sm font-medium font-body leading-5 text-gray-700">
          {' '}
          Credit Card Info*{' '}
        </label>
        <div className="p-3 mt-1 border rounded">
          <CardElement
            options={{ ...CARD_OPTIONS, disabled: loading }}
            disabled={loading}
          />
        </div>
        {!!errors.card &&
          errors.card.map((e) => (
            <p className="mt-2 text-sm text-red-600">{e.message}</p>
          ))}
      </div>

      <div className="mt-4 flex">
        <CouponField />
      </div>
    </>
  );
};

const CouponField = (props) => {
  const { state, dispatch, updateModel } = useForm();
  const { model, couponLoading, errors } = state;

  const handleCoupon = async (e) => {
    e.preventDefault();

    dispatch({ type: 'TOGGLE_COUPON_LOADING' });
    try {
      const { data } = await getCoupon({ coupon: model.coupon });

      if (data.error || data.coupon.invalid) {
        // update state with coupon error...
        dispatch({
          type: 'SET_ERRORS',
          errors: { ...errors, coupon: ['Invalid coupon'] },
        });
        console.error(data.error);
      } else {
        dispatch({
          type: 'SET_APPLIED_COUPON',
          appliedCoupon: data.coupon,
        });
        updateModel('coupon_valid', true);

        // reset errors if the previous coupon attempt was invalid
        dispatch({
          type: 'SET_ERRORS',
          errors: { ...errors, coupon: [] },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: 'TOGGLE_COUPON_LOADING' });
    }
  };

  const coupondDisabled =
    !model.coupon || model.coupon.trim().length < 1 || couponLoading;

  const disabledStyles = () => {
    return coupondDisabled ? 'opacity-75 cursor-not-allowed' : '';
  };

  return (
    <>
      <div>
        <FormInput
          errors={errors['coupon'] || []}
          model={model}
          setValue={(e) => updateModel('coupon', e)}
          value={model.coupon}
          label="Coupon Code"
          modelName="signup_form"
          attribute="coupon"
          type="text"
        />

        <input
          defaultValue={model.coupon_valid}
          name="signup_form[coupon_valid]"
          type="hidden"
        />
      </div>

      <button
        disabled={coupondDisabled}
        onClick={handleCoupon}
        className={`flex self-start mt-6 ml-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out ${disabledStyles()}`}
      >
        Apply
      </button>
    </>
  );
};

const Form = () => {
  const { state, dispatch, stripe, elements } = useForm();
  const { model, loading, paymentMethod, error, appliedCoupon } = state;

  const formRef = React.useRef(null);

  const handleSignup = async (e) => {
    e.preventDefault();

    dispatch({ type: 'TOGGLE_LOADING' });
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      dispatch({ type: 'TOGGLE_LOADING' });
      return;
    }

    // create a payment on stripe
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: `${model.full_name}`,
        email: model.email,
      },
    });

    if (!!result.error) {
      dispatch({ type: 'TOGGLE_LOADING' });
      dispatch({ type: 'SET_ERROR', error: result.error.message });
    } else {
      // update state with payment method id from createPaymentMethod
      dispatch({
        type: 'SET_PAYMENT_METHOD',
        paymentMethod: result.paymentMethod.id,
      });

      // submit form to signup_controller#create
      formRef.current.submit();
    }
  };

  const token = document.querySelector('[name=csrf-token]').content;

  return (
    <div className="static py-8 px-4 sm:rounded-lg sm:px-10">
      <form
        ref={formRef}
        action="/signup"
        method="post"
        onSubmit={handleSignup}
      >
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="signup_form[price]" value={model.price} />
        <input
          type="hidden"
          name="signup_form[platform]"
          value={model.platform}
        />
        <input
          type="hidden"
          name="signup_form[payment_method]"
          value={paymentMethod}
        />

        <fieldset>
          <FormFields />
        </fieldset>

        {error ? <p className="text-red-500">{error}</p> : ''}

        <div className="mt-4">
          {!!appliedCoupon.valid && (
            <span className="text-indigo-400">{appliedCoupon.id} applied!</span>
          )}
        </div>

        <div className="mt-6">
          <span className="block w-full rounded-md shadow-sm">
            <button
              disabled={loading}
              type="submit"
              className={`w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:border-yellow-700 focus:ring-yellow active:bg-yellow-700 transition duration-150 ease-in-out ${
                !!loading && 'opacity-75 cursor-wait'
              }`}
            >
              {!!loading ? 'Subscribing' : 'Subscribe'}
            </button>
          </span>
        </div>
      </form>

      {!!loading && (
        <p className="absolute mt-4 text-sm text-gray-600 text-center">
          Processing card info...
        </p>
      )}
    </div>
  );
};

const SignupForm = ({
  stripe_publishable_key = '',
  model = {},
  errors = {},
}) => {
  return (
    <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
      <StripeFormProvider model={model} errors={errors}>
        <Form />
      </StripeFormProvider>
    </StripeSDKLoader>
  );
};

export default SignupForm;
