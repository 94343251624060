import React from "react";
import { Input } from "./FormInputs";
import { COUNTRY_SELECT_OPTIONS } from "../../utils/countrySelectOptions";
import { StripeSDKLoader } from "../../utils/StripeSdkLoader";
import {
  CheckoutFormProvider,
  useCheckout,
  singleChargeSubmit,
} from "./StateManagement";
import { PaymentMethodSection } from "./PaymentComponents";

const MainForm = () => {
  const { state, dispatch, stripe, elements } = useCheckout();

  const handleSubmit = async (event) => {
    event.preventDefault();
    singleChargeSubmit({ state, dispatch, stripe, elements });
  };

  const { loading } = state;

  return (
    <form className="lg:w-3/4" onSubmit={handleSubmit}>
      <div>
        <h4 className="text-sm text-gray-500 font-medium">Personal info</h4>
        <Input
          type="text"
          label="First Name"
          name="first_name"
          isRequired={true}
        />
        <Input
          type="text"
          label="Last Name"
          name="last_name"
          isRequired={true}
        />
        <Input
          type="phone"
          label="Phone number"
          name="phone"
          isRequired={true}
        />
        <label className="block flex-1">
          <Input type="email" label="Email" name="email" isRequired={true} />
        </label>
      </div>

      <div className="mt-8">
        <h4 className="text-sm text-gray-500 font-medium">Home address</h4>
        <div className="mt-6 ">
          <AddressFields require_address_fields={true} />
        </div>
      </div>
      <div className="mt-8">
        <h4 className="text-sm text-gray-500 font-medium">Payment Info</h4>
        <div className="mt-6 ">
          <PaymentMethodSection />
        </div>
      </div>

      <div className="flex items-center justify-end mt-8">
        <button
          disabled={loading}
          className="flex items-center px-3 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-500 focus:outline-none focus:bg-blue-500"
        >
          {loading ? <span> Placing Order </span> : <span>Make Payment</span>}
          {/* <svg
            className="h-5 w-5 mx-2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M17 8l4 4m0 0l-4 4m4-4H3"></path>
          </svg> */}
        </button>
      </div>
    </form>
  );
};

const AddressFields = ({ require_address_fields }) => {
  return (
    <>
      <Input
        type="text"
        label="Address"
        name="line1"
        isRequired={require_address_fields}
      />

      <Input
        type="text"
        label="Apartment, suite, etc"
        name="line2"
        isRequired={false}
      />

      <Input
        type="text"
        label="City"
        name="city"
        isRequired={require_address_fields}
      />

      <Input
        type="text"
        label="State / Provence"
        name="region"
        isRequired={require_address_fields}
      />

      <Input
        type="dropdown"
        label="Country"
        name="country"
        isRequired={require_address_fields}
        selectOptions={COUNTRY_SELECT_OPTIONS}
      />

      <Input
        type="text"
        label="ZIP / postal code"
        name="postalcode"
        isRequired={require_address_fields}
      />
    </>
  );
};

const StoreCheckoutForm = (props) => {
  const {
    stripe_publishable_key,
    organization,
    current_user,
    payment_methods,
  } = props;

  return (
    <StripeSDKLoader stripe_publishable_key={stripe_publishable_key}>
      <CheckoutFormProvider
        organization={organization}
        paymentMethods={payment_methods}
        currentUser={current_user}
      >
        <MainForm />
      </CheckoutFormProvider>
    </StripeSDKLoader>
  );
};

export default StoreCheckoutForm;
