import React, {
  useContext,
  createContext,
  useReducer,
  useCallback,
} from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "../../utils/requests";

const CheckoutContext = createContext(null);

const initialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    line1: "",
    line2: "",
    city: "",
    region: "",
    country: "",
    postalcode: "",
  },
  organization: {},
  feeModel: {},
  amount: null,
  completed: false,
  loading: false,
  errors: {},
  autoPay: false,
  saveBillingInfo: false,
  transaction_id: null,
  order_id: null,
  paymentMethod: null,
  subscription_id: null,
};

export const CheckoutFormProvider = (props) => {
  // added  the form state
  const state = {
    ...initialState,
    organization: props.organization,
    paymentMethods: props.paymentMethods,
  };

  if (props.paymentMethods?.data?.length > 0) {
    state.paymentMethod = props.paymentMethods[0].id;
  }

  props.currentUser ? (state.user = props.currentUser) : null;

  const [store, dispatch] = useReducer(checkoutReducer, state);

  const value = React.useMemo(() => [store, dispatch], [store]);
  return <CheckoutContext.Provider value={value} {...props} />;
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USER":
      return { ...state, user: action.user };
    case "TOGGLE_LOADING":
      return { ...state, loading: !state.loading };
    case "TOGGLE_COMPLETED":
      return { ...state, completed: !state.completed };
    case "SET_ERROR":
      return { ...state, error: action.error };
    case "SET_ERRORS":
      return { ...state, errors: action.errors };
    case "SET_PAYMENT_METHOD":
      return { ...state, paymentMethod: action.paymentMethod };
    case "SET_ORDER_ID":
      return { ...state, order_id: action.order_id };
    case "SET_TRANSACTION_ID":
      return { ...state, transaction_id: action.transaction_id };
    case "UPDATE_SAVE_BILLING_INFO":
      return { ...state, saveBillingInfo: action.saveBillingInfo };
    default:
      return state;
  }
};

export const useCheckout = () => {
  const [state, dispatch] = useContext(CheckoutContext);

  const updateUserAttribute = useCallback(
    (attribute, value) => {
      const user = { ...state.user };
      user[attribute] = value;

      if (attribute === "password") user["password_confirmation"] = value;

      dispatch({ type: "UPDATE_USER", user });
    },
    [state, dispatch]
  );

  const setPaymentMethod = useCallback(
    (method) => {
      dispatch({ type: "SET_PAYMENT_METHOD", paymentMethod: method.id });
    },
    [state, dispatch]
  );

  const toggleSaveBillingInfo = () => {
    dispatch({
      type: "UPDATE_SAVE_BILLING_INFO",
      saveBillingInfo: !state.saveBillingInfo,
    });
  };

  return {
    state,
    dispatch,
    stripe: useStripe(),
    elements: useElements(),
    toggleSaveBillingInfo,
    updateUserAttribute,
    setPaymentMethod,
  };
};

// this is the function that handles form submission of single charges
export const singleChargeSubmit = async ({
  state,
  dispatch,
  stripe,
  elements,
}) => {
  const {
    user,
    organization,
    transaction_id,
    order_id,
    saveBillingInfo,
    paymentMethod,
  } = state;

  dispatch({ type: "TOGGLE_LOADING" });

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    dispatch({ type: "TOGGLE_LOADING" });
    return;
  }

  let params = {
    transaction_id,
    order_id,
    user_attributes: user,
    receipt_email: user.email,
    email: user.email,
    phone: user.phone,
    first_name: user.first_name,
    last_name: user.last_name,
    organization_id: organization.id,
    membership_id: user.membership_id,
    save_billing_info: saveBillingInfo,
    payment_method: paymentMethod,
  };

  // Create payment method
  // TODO PULL this into a new method,
  // only create a payment method if one is not set in the stage.
  // if a payment method is already in the state just use it. and
  // skip this method
  if (!paymentMethod) {
    const paymentMethodResponse = await createPaymentMethod({
      stripe,
      elements,
      organization,
      user,
    });

    // return early and show errors if payment method doesnt create on stripe
    if (paymentMethodResponse.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [paymentMethodResponse.error.message] },
      });
      return;
    }

    // set payment method id on the state so we dont do it again.
    dispatch({
      type: "SET_PAYMENT_METHOD",
      paymentMethod: paymentMethodResponse.paymentMethod.id,
    });
    params.payment_method = paymentMethodResponse.paymentMethod.id;
  }

  try {
    const response = await axios.post(`/o/${organization.id}/store/checkout`, {
      ...params,
    });

    dispatch({
      type: "SET_TRANSACTION_ID",
      transaction_id: response.data.transaction.id,
    });

    dispatch({
      type: "SET_ORDER_ID",
      order_id: response.data.order.id,
    });

    const result = await stripe.confirmCardPayment(
      response.data.stripe_intent.client_secret,
      { payment_method: params.payment_method }
    );

    if (result.error) {
      dispatch({ type: "TOGGLE_LOADING" });
      dispatch({
        type: "SET_ERRORS",
        errors: { base: [result.error.message] },
      });
    } else {
      if (result.paymentIntent.status === "succeeded") {
        // what do we do on the success of a card transaction
        window.location = `/o/${organization.slug}/store/checkout/receipt/?order_id=${response.data.order.id}`;
        dispatch({ type: "TOGGLE_COMPLETED" });
        dispatch({ type: "TOGGLE_LOADING" });
      }
    }
  } catch (error) {

    dispatch({ type: "TOGGLE_LOADING" });
    dispatch({ type: "SET_ERRORS", errors: error.response.data.errors });

    if (error.response.data.transaction_id) {
      dispatch({
        type: "SET_TRANSACTION_ID",
        transaction_id: error.response.data.transaction_id,
      });
    }
    if (error.response.data.order_id) {
      dispatch({
        type: "SET_ORDER_ID",
        order_id: error.response.data.order_id,
      });
    }
  }
};

const createPaymentMethod = async ({
  stripe,
  elements,
  organization,
  user,
}) => {
  return await stripe.createPaymentMethod({
    type: "card",
    card: elements.getElement(CardElement),
    metadata: {
      organization_id: organization.id,
    },
    billing_details: {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      phone: user.phone && user.phone.length > 0 ? user.phone : null,
      address: {
        line1: user.line1 && user.line1.length > 0 ? user.line1 : null,
        line2: user.line2 && user.line2.length > 0 ? user.line2 : null,
        city: user.city && user.city.length > 0 ? user.city : null,
        state: user.region && user.region.length > 0 ? user.region : null,
        country: user.country && user.country.length > 0 ? user.country : null,
        postal_code:
          user.postalcode && user.postalcode.length > 0
            ? user.postalcode
            : null,
      },
    },
  });
};
