import React, { useState, Fragment } from "react";
import { Switch, Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const ToggleAutoInvoicing = ({ autoInvoice, setAutoInvoice }) => {

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex flex-grow flex-col">
        <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
          Automatically Invoice
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          Automatically generate and send invoices to members.
        </Switch.Description>
      </span>
      <input type="hidden" name="fund[auto_invoice]" value={autoInvoice} />
      <Switch
        checked={autoInvoice}
        onChange={setAutoInvoice}
        className={classNames(
          autoInvoice ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            autoInvoice ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  )
}

const ReminderForm = (props) => {
  const { reminder, index } = props;
  const [daysBeforeOrAfter, setDaysBeforeOrAfter] = useState(reminder.days_before_or_after);
  return (
    <div className="p-3 border rounded border-gray-300 my-8 relative">
      {index === 0 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Reminder 1</p>}
      {index === 1 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Reminder 2</p>}
      {index === 2 && <p className="text-gray-900 font-medium bg-white ml-2 px-1 absolute -top-3 ">Overdue Reminder 1</p>}
      {reminder.id && <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][id]`} value={reminder.id} />}
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][name]`} value={reminder.name} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][reminder_type]`} value={reminder.reminder_type} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][interval]`} value={reminder.interval} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][delivery_method]`} value={reminder.delivery_method} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][status]`} value={reminder.status} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][time_of_day]`} value={reminder.time_of_day} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][time_zone]`} value={reminder.time_zone} />
      <input type="hidden" name={`fund[scheduled_reminders_attributes][${index}][email_subject]`} value={reminder.email_subject} />
      <p className="text-gray-400 font-medium">
        {index === 0 && (<> The first <span className="text-gray-900"> reminder </span> be sent out </>)}
        {index === 1 && (<>  Another <span className="text-gray-900"> reminder </span> will be sent out </>)}
        {index === 2 && (<> And an <span className="text-gray-900"> overdue </span> notice will be sent out </>)}
        <select
          name={`fund[scheduled_reminders_attributes][${index}][days_before_or_after]`}
          className="text-gray-900 border-0 border-b"
          value={daysBeforeOrAfter}
          onChange={(e) => setDaysBeforeOrAfter(e.target.value)}>
          {Array.from(Array(28).keys()).map((i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
        {index === 0 && ("day(s) before the due date.")}
        {index === 1 && ("day(s) before the due date.")}
        {index === 2 && ("day(s) after the due date.")}
      </p>

      <div className="grid grid-cols-2">
        <div className="sm:col-span-1">
          <label className="block text-sm font-medium leading-5 text-gray-700">
            Message
          </label>
          <p className="mt-1 text-sm text-gray-500">
            Add a short message that will appear on the email
          </p>
          <div className="mt-1 relative rounded-md shadow-sm">
            <textarea
              name={`fund[scheduled_reminders_attributes][${index}][message]`}
              defaultValue={reminder.message}
              className="form-input block w-full focus:ring-indigo-500  transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoiceScheduleForm = (props) => {
  const { invoicing_fund, due_day_options, scheduled_reminders } = props;
  const [dueDay, setDueDay] = useState(invoicing_fund.due_day);
  const [autoInvoice, setAutoInvoice] = useState(invoicing_fund.auto_invoice);
  return (
    <div className="">
      <h3 className="text-xl font-medium text-indigo-800">Invoice Schedule</h3>
      <div className="px-4">
        <p className="text-gray-400 font-medium">
          <span className="text-gray-900"> Invoices </span> for these dues will be due on the
          <select name="fund[due_day]" className="text-gray-900 border-0 border-b" value={dueDay} onChange={(e) => setDueDay(e.target.value)}>
            {due_day_options.map((o) => (
              <option key={o[1]} value={o[1]}>{o[0]}</option>
            ))}
          </select>
          of every month.
        </p>
      </div>

      <h3 className="text-xl mt-4 font-medium text-indigo-800">Invoice Reminders</h3>
      <div className="px-4">
        <ToggleAutoInvoicing autoInvoice={autoInvoice} setAutoInvoice={setAutoInvoice} />
        <div className="overflow-hidden">
          <Transition
            show={autoInvoice}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >

            {scheduled_reminders.map((reminder, index) => {
              return <ReminderForm key={index} reminder={reminder} index={index} />
            })}
          </Transition>
        </div>
      </div>
    </div>
  )
}

export default InvoiceScheduleForm;